<template>
    <b-overlay :show="loading" opacity="0.2">
        <b-card>
            <div class="row justify-content-md-start justify-content-center">
                <div class="col-auto">
                    <b-img height="200px" class="mr-0 mr-md-2 mb-2 mb-md-0" :src="require('@/assets/images/illustrations/profile.svg')"></b-img>
                </div>
                <div class="col-auto">
                    <h2 class="text-center text-sm-left">{{$safe(profile,'FullName')}}</h2>
                    <h2 class="text-muted text-center text-sm-left">#{{$safe(profile,'Email')}}</h2>
                    <div class="row">
                        <div class="col-md-6 mb-1">
                            <label class="form-label">Şifre</label>
                            <input type="password" v-model="password" class="form-control" placeholder="Şifre">
                        </div>
                        <div class="col-md-6 mb-1">
                            <label class="form-label">Şifre Tekrarı</label>
                            <input type="password" v-model="passwordReply" class="form-control" placeholder="Şifre Tekrarı">
                        </div>
                        <div class="col-12 text-right">
                            <v-executer :disabled="password == null || password == '' || password != passwordReply" text="Güncelle" :loading="updating" :action="update"></v-executer>
                        </div>
                    </div>
                </div>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
import { BCard,BOverlay,BImg} from 'bootstrap-vue'
import  { UserApi }  from '@/api/user'
export default {
    components:{
        BCard,
        BOverlay,
        BImg
    },
    data() {
        return {
            profile:{
                Email:null,
                FullName:null
            },
            loading:false,
            password:null,
            passwordReply:null,
            updating:false
        }
    },
    mounted(){
        this.getProfile()
    },
    methods:{
        clearForm(){
            this.password = null
            this.passwordReply = null
        },
        getProfile(){
            this.loading = true
            UserApi.CurrentUserProfile().then(res=>{
                this.profile.Email = res.data.Data.Email 
                this.profile.FullName = res.data.Data.FullName 
                this.loading = false
            })
        },
        update(){
            this.updating = true
            UserApi.UpdatePassword({
                Password:this.password,
                RePassword:this.passwordReply
            }).then(res=>{
                this.$result(res)
                this.updating = false
                if(res.data.Success){
                    this.clearForm()
                }
            }).catch(err=>{
                this.updating = false
                this.$result(null,err)
            })
        }
    }
}
</script>

<style>

</style>